import type React from "react";

interface PageHeaderProps {
  title: string;
  subtitle?: string;
  isCollapsed?: boolean;
  //   tag?: string;
  //   loading?: boolean;
  //   onApprove?: () => void;
  //   onAddComment?: () => void;
  //   onDownload?: () => void;
  //   onToggleTheme: () => void;
  //   isDarkMode: boolean;
}

const PageHeader: React.FC<PageHeaderProps> = ({
  title,
  subtitle,
  isCollapsed,
  //   tag,
  //   loading,
  //   onApprove,
  //   onAddComment,
  //   onDownload,
  //   onToggleTheme,
  //   isDarkMode,
}) => {
  const ll = "Dd";
  return (
    <div className="w-full fixed z-50">
      <div className="h-full">
        <header className="bg-blue-600 text-white p-4 flex justify-between h-154">
          <div className="flex">
            <div className={`${isCollapsed ? "w-[5rem]" : "w-[9rem]"}`}>s</div>
            <div>
              <h1>{title}</h1>
              <h1>
                {subtitle} {ll}
              </h1>
            </div>
          </div>
          <div>
            <button className="bg-white text-blue-600 px-4 py-2" type="button">
              Approve
            </button>
            <button className="bg-white text-blue-600 px-4 py-2" type="button">
              Add Comment
            </button>
            <button className="bg-white text-blue-600 px-4 py-2" type="button">
              Download
            </button>
          </div>
        </header>
      </div>
    </div>
  );
};

export default PageHeader;
