const basePath = process.env.REACT_APP_REST_ENDPOINT || "";

type ApiConfig = {
  baseURL: string;
  headers: {
    "Content-Type": string;
  };
  paths: Record<string, string>;
  errorCode: number[];
  successCode: number[];
  excludeToken: string[];
};

const apiConfig: ApiConfig = {
  baseURL: basePath,
  headers: {
    "Content-Type": "application/json",
  },
  paths: {
    signup: "/auth/signup",
    signin: "/auth/signin",
    verifyToken: "/auth/verifyToken",
    refreshToken: "/auth/refresh",
    contactProfile: "/contact",
    kpiContact: "/kpi-proposals/contact",
    reviewContact: "/proposals/contact",
    buySellReviewContact: "/bs-proposals/contact",
    proposalPendingbasicInfoUploadDocument: "/proposals-pending/upload/proposal-document",
    bsProposalPendingbasicInfoUploadDocument: "/bs-proposals-pending/upload/proposal-document",
    proposalPendingAdditionReqUploadDocument: "/proposals-pending/upload",
    proposalPendingbasicInfoDeleteDocument: "/proposals-pending/proposal-document",
    bsProposalPendingbasicInfoDeleteDocument: "/bs-proposals-pending/proposal-document",
    kpiDeleteDocument: "/kpi-proposals-pending/proposal-document",
    proposalPendingFinalDeleteDocument: "/proposals-pending/signed-proposal",
    bsCreatePlan: "/bs-proposals-pending/create-plan",
    kpiCreatePlan: "/kpi-proposals-pending/create-plan",
    reviewContactTimeline: "/proposals/proposal-logs",
    reviewDuplicateProposalID: "/proposals/duplicate-proposal",
    bsReviewDuplicateProposalID: "/bs-proposals/duplicate-proposal",
    contactDraft: "/contact/list/drafts",
    blockToken: "/auth/block/otp",
    blockVerifyToken: "/auth/block/otp/verify",
    resetEmail: "/auth/reset/email",
    resetPhone: "/auth/reset/mobile",
    resetEmailToken: "/auth/reset/email/token",
    resetPhoneToken: "/auth/reset/mobile/token",
    contactHealth: "/contact/health",
    resendResetPassword: "/auth/resetPasswordLink",
    resetPasswordEmail: "/auth/reset/email/createpassword",
    resetPasswordPhone: "/auth/reset/mobile/createpassword",
    contactBlock: "/contact/block",
    plansList: "/v1/stripe/plans",
    planChange: "/v1/stripe/create-checkout-session",
    paymentUpdateStatus: "/v1/stripe/update-payment-status",
    inviteEmail: "/invitations",
    inviteEmailAssistant: "/invitations/assistant",
    inviteAgency: "/agency",
    resendInvite: "/invitations/resendInvite",
    getUsers: "/users/profile-names",
    findAdmins: "/users/admin",
    profileCreate: "/profile/create",
    logout: "/auth/logout",
    profileOTP: "/auth/verify/mobile/token",
    agentBrandingUpload: "/upload",
    agencyBrandingSave: "/agency-branding",
    agentBrandingSave: "/agent-branding",
    allBrandingDetails: "/agent-branding/listAllBrandings",
    activeBranding: "/agents/setCurrentBranding",
    activeAgencyBranding: "/agency/setCurrentBranding",
    deleteAgent: "/agents",
    agents: "/agents/onboarded",
    pendingAgents: "/agents/pending",
    agencies: "/agency/list",
    requestProposal: "/v1/proposals/request-proposal",
    requestProposals: "/v1/proposals/list-proposals/requested",
    adminDraft: "/v1/proposals/list-proposals/draft",
    pendingReviews: "/v1/proposals/list-proposals/pending-review",
    pendingRequest: "/v1/proposals/list-proposals/received",
    subDomain: "/subdomain/set",
    resetPasswordLink: "/auth/reset/link",
    proposalTypes: "/v1/proposals/proposal-types",
    lookupBefore: "/lookup/before",
    lookupAfter: "/lookup/after",
    lookupAfterAgent: "/lookup/assistant/after",
    contactFinancialInfo: "/contact/finance/create",
    superAdminProfile: "/super-admin/profile",
    agentProfile: "/profile/agent",
    agencyProfile: "/profile/agency",
    superAdminAgents: "/super-admin/agents",
    financialInfo: "/contact/finance",
    addPolicyInput: "/v1/proposals/add-policy-input",
    policyInputCSVData: "/v1/proposals/policy-input",
    proposalBuyNSell: "/bs-proposals/bs-proposal-valuations",
    insuranceCompanies: "/v1/proposals/insurance-companies",
    userProfile: "/profile",
    contact: "/contact",
    contactTransaction: "/contact/transaction",
    contactPersonalInfoEdit: "/contact/profile",
    profilePhoneOtp: "/profile/phone/token",
    profilePhoneVerifyOtp: "/profile/phone/verify",
    agentProfilePicture: "/profile/pictureUpload",
    agentContactPersonalInfoSave: "/contact/profile",
    resetProfileEmail: "/profile/email/token",
    resetProfileEmailToken: "/profile/email/verify",
    resetProfilePhone: "/profile/phone/token",
    resetProfilePhoneToken: "/profile/phone/verify",
    profile: "/profile",
    profilePictureUpload: "/profile/contactProfileUpload",
    profilePictureDelete: "/profile/profilePicture",
    deleteBrandingLogo: "/agent-branding/branding-logo",
    contactHealthInfo: "/contact/health/create",
    proposalsPlanAdminSplitDollar: "/proposals/plan-admin-split-dollar",
    createProposalContactInfo: "/proposals/contact",
    revokeProposalContact: "/proposals/action/revoked",
    revokeProposal: "/proposals/action/pending-revoked",
    pdfReport: "/proposals/pdf-report",
    createProposal: "/proposals/create-proposal",
    sendProposal: "/proposals/send-proposal",
    reqSign: "/proposals/contact/req-sign",
    proposalsInsuranceCompanies: "/v1/proposals/insurance-companies",
    proposalActionReview: "/proposals/action/reviewed",
    planSplitDollar: "/proposals/plan-split-dollar",
    commission: "/commission",
    sendCommission: "/commission/create",
    deleteCommission: "/commission/delete",
    saveProposalPlan: "/proposals/plan-data-save",
    loanPlanProjection: "/proposals/plan-projection",
    createProposalOtherDetails: "/proposals/other-details",
    contactProposals: "/v1/proposals/list-proposals/contact",
    proposalTimeline: "/proposals/proposal-logs",
    proposalPdf: "/proposals/report",
    proposalPendingPdf: "/proposals-pending/report",
    proposalChanges: "/proposals/change-request",
    pendingApprove: "/v1/proposals/list-proposals/pending-approve",
    IsProgressApprove: "/proposals/list-proposals/pending-in-progress",
    pendingProposal: "/v1/proposals/list-proposals/approved-pending",
    pendingDocumentListView: "/proposals-pending/list-proposal-document",
    uploadSignedReport: "/proposals/upload/signed-report",
    requestNewSignature: "/proposals/action/req-new-signature",
    approveProposal: "/proposals/action/approved",
    helloSignUrl: "/proposals/hello-sign-report",
    checkHelloSignStatus: "/proposals/hello-sign-report-status",
    proposalPendingBasicInfo: "/proposals-pending/basic-info",
    bsproposalPendingBasicInfo: "/bs-proposals-pending/basic-info",
    kpiProposalPendingBasicInfo: "/kpi-proposals-pending/basic-info",
    proposalPendingHelloSign: "/proposals-pending/hello-sign-report",
    proposalPendingHelloSignStatus: "/proposals-pending/hello-sign-report-status",
    proposalPendingInsuranceUnderwriting: "/proposals-pending/insurance-underwriting",
    proposalPendingPlanFinalize: "/proposals-pending/plan-finalization",
    proposalPendingSaveDetails: "/proposals-pending/save-details",
    bsProposalPendingSaveDetails: "/bs-proposals-pending/save-details",
    kpiProposalPendingSaveDetails: "/kpi-proposals-pending/save-details",
    proposalPendingApproveDoc: "/proposals-pending/approve",
    proposalPendingRejectDoc: "/proposals-pending/reject",
    proposalPendingDocLink: "/proposals-pending/doc-link",
    inactiveProposals: "/proposals/list-proposals/inactive",
    inactivePending: "/proposals/list-proposals/pending-inactive",
    plansGraphViews: "/plans/details",
    plansTableView: "/plans/list/all",
    loansTableView: "/plans/loan/list/all",
    pastDues30Days: "/plans/list-upcoming-reviews/past-dues-30-days",
    pastDues: "/plans/list-upcoming-reviews/past-dues",
    next30Days: "/plans/list-upcoming-reviews/next-30-days",
    next45Days: "/plans/list-upcoming-reviews/next-45-days",
    deleteAdditionalReqTask: "/proposals-pending/additional-document",
    planFinalization: "/proposals-pending/plan-finalization",
    kpiPlanFinalization: "/kpi-proposals-pending/plan-finalization",
    updateProposal: "/proposals-pending/plan-finalization/proposal/updated",
    billingList: "/billing/list/all",
    billingInvoice: "/billing/invoice",
    conciergeService: "/subscription/conciergeService",
    viewPolicyDetails: "/plans/policy-review",
    viewPlanDetails: "/plans/plan-review",
    summaryGraph: "/plans/plan-graph",
    planSummary: "/plans/plan-summary",
    loanSummary: "/plans/loan-summary",
    policyEffectiveDate: "/plans/policy-effective-date",
    bsPolicyShareholders: "/bs-plans/policy/shareholders",
    kpiPolicyEmployees: "/kpi-plans/policy/employees",
    bsPolicySummary: "/bs-plans/policy-summary",
    kpiPolicySummary: "/kpi-plans/policy-summary",
    commissionRequest: "/commission/requests",
    insuranceCompany: "/insurance-company",
    insuranceProduct: "/insurance-product",
    cancelSubscription: "/subscription/cancel",
    reportsInsuranceCompany: "/reports/insurance-company",
    reportsIappCommissions: "/reports/iapp-commissions",
    reportsAgentCommissions: "/reports/agent-commission",
    commissionApproval: "/commission/approval",
    reportsAgencyCommissions: "/reports/agency-commission",
    viewCommissionUser: "/commission/user",
    planEffectiveDate: "/plans/plan-effective-date",
    loanReview: "/plans/loan-review",
    agencyCommissionReports: "/reports/agency-commission",
    afrDetails: "/super-admin/master-data/afr",
    provisionDetails: "/super-admin/master-data/provisions",
    requestBuySellProposal: "/bs-proposals/request-proposal",
    requestKeyPersonProposal: "/kpi-proposals/request-proposal",
    createBuySellProposalContactInfo: "/bs-proposals/contact",
    buySellProposalMasterDataProvisions: "/super-admin/master-data/provisions",
    buySellProposalProvisions: "/bs-proposals/provisions",
    addBuySellProposalProvisions: "/bs-proposals/provisions",
    fetchProposedPolicyLedger: "/bs-proposals/life-policy-ledger",
    fetchProposedDisabilityPolicyLedger: "/bs-proposals/disability-policy-ledger",
    buySellProposalPendingBusinessValuation: "/bs-proposals-pending/business-valuation",
    buySellProposalPendingApproveDoc: "/bs-proposals-pending/approve",
    buySellProposalPendingRejectDoc: "/bs-proposals-pending/reject",
    kpiProposalPendingApproveDoc: "/kpi-proposals-pending/approve",
    kpiProposalPendingRejectDoc: "/kpi-proposals-pending/reject",
    buySellProposalPendingBusinessValuationUploadDocument:
      "/bs-proposals-pending/upload/proposal-document",
    kpiProposalPendingBusinessValuationUploadDocument:
      "/kpi-proposals-pending/upload/proposal-document",
    bsProposalsPendingBasicInfoGenerateInvoice: "/bs-proposals-pending/basic-info/generate-invoice",
    kpiProposalsPendingBasicInfoGenerateInvoice:
      "/kpi-proposals-pending/basic-info/generate-invoice",
    proposalsPendingBasicInfoGenerateInvoice: "/proposals-pending/basic-info/generate-invoice",
    buySellProposalPendingBusinessValuationDeleteDocument:
      "/bs-proposals-pending/proposal-document",
    shareHolderLedgerFetchOperation: "/bs-proposals/shareholder-ledger",
    shareHolderLedgerFetchOperationplan: "/bs-plans/shareholder-ledger",
    kpiLedgerFetchOperationplan: "/kpi-plans/employee-ledger",
    kpiEmployeeLedgerPlanDetails: "/kpi-plans/employee-ledger",
    shareHolderLedgerOperations: "/bs-proposals/shareholder",
    shareHolderLedgerList: "/bs-proposals/shareholders",
    kpishareHolderLedgerList: "/kpi-proposals/employees",
    requestProposalProgress: "/bs-proposals/progress",
    disabilityInsurance:
      "/bs-proposals-pending/disability-insurance-underwriting/{proposalId}/{pendingId}",
    kpidisabilityInsurance:
      "/kpi-proposals-pending/disability-insurance-underwriting/{proposalId}/{pendingId}",
    disabilityInsuranceSave: "/bs-proposals-pending/save-details",
    kpidisabilityInsuranceSave: "/kpi-proposals-pending/save-details",
    disabilityInsuranceShareholder:
      "/bs-proposals-pending/disability-insurance-underwriting/shareholders",
    kpidisabilityInsuranceShareholder:
      "/kpi-proposals-pending/disability-insurance-underwriting/employees",
    disabilityInsuranceShareholderPlan: "/bs-plans/disability-insurance-underwriting/shareholders",
    disabilityInsuranceShareholderSave:
      "/bs-proposals-pending/disability-insurance-underwriting/shareholders/{proposalId}",
    kpiDisabilityInsuranceShareholderSave:
      "/kpi-proposals-pending/disability-insurance-underwriting/employee",
    disabilityInsuranceInvoiceGenerate:
      "/bs-proposals-pending/disability-insurance-underwriting/generate-invoice",
    lifeInsuranceUnderwriting: "/bs-proposals-pending/life-insurance-underwriting/shareholders",
    kpilifeInsuranceUnderwriting: "/kpi-proposals-pending/life-insurance-underwriting/employees",
    lifeInsuranceUnderwritingPlan: "/bs-plans/life-insurance-underwriting/shareholders",
    lifeInsuranceUnderwritingPost:
      "/bs-proposals-pending/life-insurance-underwriting/shareholders/{proposalId}",
    kpiLifeInsuranceUnderwritingPost: "/kpi-proposals-pending/life-insurance-underwriting/employee",
    lifeInsurancePending:
      "/bs-proposals-pending/life-insurance-underwriting/{proposalId}/{pendingId}",
    kpilifeInsurancePending:
      "/kpi-proposals-pending/life-insurance-underwriting/{proposalId}/{pendingId}",
    lifeInsuranceInvoiceGeneration:
      "/bs-proposals-pending/life-insurance-underwriting/generate-invoice",
    lifeInsuranceuploadDoc: "/bs-proposals-pending/upload/proposal-document",
    lifeInsuranceSaveDetails: "/bs-proposals-pending/save-details",
    kpiLifeInsuranceSaveDetails: "/kpi-proposals-pending/save-details",
    buySellproposalPendingPlanFinalize: "/bs-proposals-pending/plan-finalization",
    buySellproposalPendingSaveDetails: "/bs-proposals-pending/save-details",
    buySellproposalPendingbasicInfoUploadDocument: "/bs-proposals-pending/upload/proposal-document",
    buySellcreateProposal: "/bs-proposals/create-proposal",
    buySellsendProposal: "/bs-proposals/send-proposal",
    buySellCreatePlan: "/bs-proposals-pending/create-plan",
    buySellplanSummary: "/bs-plans/plan-summary",
    kpiPlanSummary: "/kpi-plans/plan-summary",
    kpiproposalPendingUploadDocument: "/kpi-proposals-pending/upload/proposal-document",
    addtionalRequestPlanSummary: "/bs-plans/review/additional-benefits",
    valuationAndContributionReview: "/bs-plans/valuation-contributions-review",
    kpiPlanReview: "/kpi-plans/plan-review",
    triggeringEvents: "/bs-plans/triggering-events",
    shareHoldersEventTypes: "/bs-plans/triggering-events/shareholders-event-types",
    requestAddtionalRequestPlanSummary: "/bs-plans/additional-benefits",
    transectionLedger: "/bs-plans/transaction-ledger",
    policyTransectionLedger: "/bs-plans/policy-transaction-ledger",
    lifePolicyLedgerPlan: "/bs-plans/life-policy-ledger",
    lifePolicyLedgerPlanPost: "/bs-plans/life-policy-ledger",
    kpilifePolicyLedgerPlan: "/kpi-plans/life-policy-ledger",
    kpilifePolicyLedgerPlanPost: "/kpi-plans/life-policy-ledger",
    disabilityLedgerPlan: "/bs-plans/disability-policy-ledger",
    kpidisabilityLedgerPlan: "/kpi-plans/disability-policy-ledger",
    transactionLedgerEndpoint: "/bs-plans/transaction-ledger",
    settingsProfile: "/profile/phone/otp",
    settingsVerifyOtp: "/profile/otp/verify",
    settingsChangePassword: "/auth/changepassword",
    contactList: "/contact/list/all",
    setLoanAFR: "/plans/set-loan-afr",
    agencyProfileAdmin: "/profile/agency",
    agentListing: "/agents/list-agency-agents",
    agentsAgencyCounts: "/agents/agency-agents-counts",
    brandingList: "/agency/sub-agencies",
    caseManager: "/super-admin/admins",
    inviteAgentFromAgency: "/invitations/agents-in-agency",
    agentsContactAndPlanCount: "/agents/contact-and-plan-count",
    agentProfileId: "/profile",
    superAdminAgentProfileEdit: "/profile/agent",
    adminAgentContactListing: "/contact/lists",
    teamListing: "/super-admin/team-members",
    removeTeamMember: "/super-admin/remove-member",
    agentOrgChart: "/agents/org-chart",
    getAdminRoles: "/super-admin/master-data/role",
    postInviteTeamMember: "/invitations/admin",
    superAdminPending: "/super-admin/admins/pending",
    orgChart: "/agency/org-chart",
    organisationRoles: "/super-admin/master-data/role",
    superAdminsList: "/super-admin/admins",
    superAdminSetRole: "/super-admin/set-role",
    saAgencyAdminsList: "/agency/sub-agencies",
    saAssistantsList: "/agency/assistants",
    contactSummary: "/contact/summary",
    updateAgentRole: "/agency/agent-role",
    removeAgentRole: "/agency/remove/agent-role",
    agencyEditProfile: "/agency/profile",
    subAgencyInvite: "/agency/subagency",
    agencySubAgencyStats: "/agency/sub-agency-stats",
    agentAssistant: "/agents/assistants",
    convertToAgent: "/agency/sub-agency-manager-to-agent",
    deleteAssistant: "/agency/assistant",
    deleteAdminAgentAssistant: "/super-admin/assistant",
    agencyManageAssistant: "/agency/manage-assistant",
    updateDeviceToken: "/notifications/update-device-token",
    notifications: "/notifications",
    readNotification: "/notifications/mark-read",
    unreadNotification: "/notifications/mark-unread",
    readAllNotifications: "/notifications/mark-all-read",
    readProposalReceivedNotification: "/notifications/mark-proposal-received-read",
    genrateLoanReviewInvoice: "/plans/generate-loan-review-invoice",
    deleteLoanReviewInvoice: "/plans/loan-review-invoice",
    subscriptionCancel: "/stripe/subscription/cancel",
    policyPastDues30Days: "/plans/policy-upcoming-reviews/past-dues-30-days",
    policyPastDues: "/plans/policy-upcoming-reviews/past-dues",
    policyNext30Days: "/plans/policy-upcoming-reviews/next-30-days",
    policyNext45Days: "/plans/policy-upcoming-reviews/next-45-days",
    authSwitchUser: "/auth/switch",
    saveThemeSettings: "/profile/setting/theme",
    saveFontSettings: "/profile/setting/fontsize",
    validateInvite: "/invitations/validateInvite",
    generateEmployeeInvoice: "/plans/generate-plan-review-employee-invoice",
    generateEmployerInvoice: "/plans/generate-plan-review-employer-invoice",
    deleteEmployeeInvoice: "/plans/plan-review-employee-invoice",
    deleteEmployerInvoice: "/plans/plan-review-employer-invoice",
    deleteBasicInfoInvoice: "/proposals-pending/basic-info/invoice",
    restoreProposal: "/proposals-pending/plan-finalization/restore-proposal",
    planUpgrade: "/stripe/subscription/upgrade",
    chatUser: "/chat/user",
    chatFileUpload: "/chat/upload/doc",
    chatFile: "/chat/doc",
    chatFileRemove: "/chat/remove/doc",
    proposalStats: "/proposals/stats",
    waiveOffEmployeeInvoice: "/plans/waive-off-late-fee-employee-invoice",
    waiveOffEmployerInvoice: "/plans/waive-off-late-fee-employer-invoice",
    waiveOffLoanReviewInvoice: "/plans/waive-off-late-fee-loan-review-invoice",
    waiveOffBSValuationInvoice: "/bs-plans/waive-off-late-fee",
    kpiWaiveOffBSValuationInvoice: "/kpi-plans/waive-off-late-fee",
    emailNotification: "/profile/setting/emailNotification",
    loanDetail: "/plans/loan/details",
    attentions: "/notifications/attentions",
    policyBenefits: "/plans/policy-benefits",
    cashValues: "/plans/cash-values",
    kpiProposalContactInfo: "/kpi-proposals/contact",
    kpiEmployeeLedger: "/kpi-proposals/employees-ledger",
    kpiProposedLifePolicyLedger: "/kpi-proposals/life-policy-ledger",
    kpiProposedDisabilityPolicyLedger: "/kpi-proposals/disability-policy-ledger",
    kpiProposalValuation: "/kpi-proposals/proposal-valuation",
    kpiProposalEmployee: "/kpi-proposals/employee",
    kpiEmployeesLedgerEmployee: "/kpi-proposals/employee",
    kpiCreateProposal: "/kpi-proposals/create-proposal",
    kpiSendProposal: "/kpi-proposals/send-proposal",
    kpiProgress: "/kpi-proposals/progress",
    kpiDuplicateProposal: "/kpi-proposals/duplicate-proposal",
    insuranceCompanyFileUpload: "/insurance-company/upload/doc",
    insuranceCompanyDeleteDocument: "/insurance-company/doc",
    addAdvisorToPlan: "/plans/add-advisor",
    removeAdvisorFromPlan: "/plans/remove-advisor",
    addAdvisorToContact: "/contact/add-advisor",
    removeAdvisorFromContact: "/contact/remove-advisor",
    allAgents: "/super-admin/all-agents",
    addAgentSDCommissionTracking: "/plans/commissions/add-agent",
    addAgentBSCommissionTracking: "/bs-plans/commissions/add-agent",
    addAgentKPCommissionTracking: "/kpi-plans/commissions/add-agent",
    getSDCommissionTracking: "/plans/commissions",
    getBSCommissionTracking: "/bs-plans/commissions",
    getKPCommissionTracking: "/kpi-plans/commissions",
    removeAgentSDCommissionTracking: "/plans/commissions/remove-agent",
    removeAgentBSCommissionTracking: "/bs-plans/commissions/remove-agent",
    removeAgentKPCommissionTracking: "/kpi-plans/commissions/remove-agent",
    updateAgentSDCommissionTracking: "/plans/commissions/update-agent",
    updateAgentBSCommissionTracking: "/bs-plans/commissions/update-agent",
    updateAgentKPCommissionTracking: "/kpi-plans/commissions/update-agent",
    getAgentCommissionSD: "/plans/agent-commissions",
    getAgentCommissionBS: "/bs-plans/agent-commissions",
    getAgentCommissionKP: "/kpi-plans/agent-commissions",
    getInvoicePaymentIntent: "/stripe/invoice",
  },
  errorCode: [400, 403, 401, 412, 500, 405],
  successCode: [200, 201, 202, 203, 204, 205, 206, 207],
  excludeToken: ["/auth/signup", "/auth/signin", "/auth/verifyToken", "/auth/refresh"],
};

export default apiConfig;
