import { THEME } from "app/utils/types";
import { getSessionStore } from "app/utils/sessionStore";
import { Tooltip } from "primereact/tooltip";
import { Link } from "react-router-dom";
import IAPPLogoDarkIcon from "../../assets/IAPPLogoDarkIcon.svg";
import SummaryIcon from "../../assets/svg/summary.svg";
import SolutionIcon from "../../assets/svg/solution.svg";
import FundingIcon from "../../assets/svg/funding.svg";
import ProjectionIcon from "../../assets/svg/projection.svg";
import SignatureIcon from "../../assets/svg/signature.svg";
import DisclosureIcon from "../../assets/svg/disclosure.svg";
import AppendixIcon from "../../assets/svg/appendix.svg";
import ExitIcon from "../../assets/svg/exit.svg";
import RectanglecurvedIcon from "../../assets/Rectanglecurved.svg";

const InteractiveProposalSidebar = ({ isCollapsed, setIsCollapsed }: any) => {
  const globalTheme = getSessionStore("globalTheme");
  const menuItems = [
    { label: "Summary", path: "summary", icon: SummaryIcon },
    { label: "Solution", path: "solution", icon: SolutionIcon },
    { label: "Funding", path: "funding", icon: FundingIcon },
    { label: "Projection", path: "projection", icon: ProjectionIcon },
    { label: "Signature", path: "signature", icon: SignatureIcon },
    { label: "Disclosure", path: "disclosure", icon: DisclosureIcon },
    { label: "Appendix", path: "appendix", icon: AppendixIcon },
  ];

  const getThemeBasedIAPPBranding = () => {
    if (isCollapsed) {
      return globalTheme === THEME.DARK
        ? IAPPLogoDarkIcon
        : process.env.REACT_APP_ENVIRONMENT === "PROD"
        ? `${process.env.REACT_APP_ASSET_URL_PROD}/bee_icon.png`
        : `${process.env.REACT_APP_ASSET_URL_DEV}/BEE+ICON.png`;
    }
    return globalTheme === THEME.DARK
      ? process.env.REACT_APP_ENVIRONMENT === "PROD"
        ? `${process.env.REACT_APP_ASSET_URL_PROD}/iapp_white.png`
        : `${process.env.REACT_APP_ASSET_URL_DEV}/iapp_white.png`
      : process.env.REACT_APP_ENVIRONMENT === "PROD"
      ? `${process.env.REACT_APP_ASSET_URL_PROD}/iapp_color.png`
      : `${process.env.REACT_APP_ASSET_URL_DEV}/iapp_color.png`;
  };

  return (
    <div className={`px-1 mb-4 relative z-[100] ${isCollapsed ? "w-[3.75rem]" : "w-[7.5rem]"}`}>
      <aside
        className="py-5 h-[100vh] fixed z-100 ml-2"
        style={{ width: isCollapsed ? "3.75rem" : "7.5rem" }}
      >
        <nav
          className="scrollbar-hide h-full flex flex-col justify-content-between overflow-y-auto border-r border-primary-bg-border border-[1px] shadow-lg rounded-2xl pt-3 pb-4 bg-light-primary dark:bg-blue-1100"
          style={{
            scrollbarWidth: "thin",
          }}
        >
          <div>
            <div className="absolute group right-[-20px] top-90 items-center">
              <button
                type="button"
                onClick={() => setIsCollapsed(!isCollapsed)}
                className="flex items-center justify-center rounded-lg  "
              >
                {isCollapsed ? (
                  <i
                    className="left-angles pi pi-angle-double-left p-2 rounded-lg bg-light-primary border-[1px] border-gray-300 dark:!border-colorBoder dark:bg-blue-1000 hover:bg-gray-100 dark:hover:!bg-blue-800"
                    style={{ fontSize: "1rem" }}
                    data-pr-tooltip="Collapse"
                  >
                    <Tooltip target=".left-angles" className="tooltip-wrapper" />
                  </i>
                ) : (
                  <i
                    className="right-angles pi pi-angle-double-right  p-2 rounded-lg  bg-light-primary border-[1px] border-gray-300    dark:border-[1px] dark:!border-colorBoder dark:bg-blue-1000 hover:bg-gray-100 dark:hover:!bg-blue-800"
                    style={{ fontSize: "1rem" }}
                    data-pr-tooltip="Expand"
                  >
                    <Tooltip target=".right-angles" className="tooltip-wrapper" />
                  </i>
                )}
              </button>
            </div>
            <img src={getThemeBasedIAPPBranding()} alt="logo" className="mb-10 px-3" />
            <div className="flex justify-center items-center">
              <ul
                className={`leftPanelUl flex-1 text-center mb-3 ${!isCollapsed ? "w-[48px]" : ""}`}
              >
                {menuItems.map((item: any) => {
                  const lb = item.label;
                  const isActive = window.location.hash.includes(item.path);
                  return (
                    <li
                      className={`my-3 relative group w-full flex justify-center items-center ${
                        isCollapsed ? "px-2" : "px-3"
                      } ${isActive ? "text-blue-500" : ""}`}
                    >
                      {isActive && (
                        <img
                          src={RectanglecurvedIcon}
                          className="absolute left-0"
                          alt="RectanglecurvedIcon"
                        />
                      )}
                      <Link key={lb} to={item.path} className="flex flex-col items-center">
                        <img
                          src={item.icon}
                          alt={item.label}
                          className="transition-transform duration-200 hover:scale-110"
                        />
                        {!isCollapsed && <div className="text-sm">{item.label}</div>}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>

            <div className="text-xs absolute bottom-75 left-0 right-0 flex flex-col items-center">
              <img
                src={ExitIcon}
                alt="exit"
                className="transition-transform duration-200 hover:scale-110"
              />
              {!isCollapsed && <div className="text-sm">Exit</div>}
            </div>
          </div>
        </nav>
      </aside>
    </div>
  );
};

export default InteractiveProposalSidebar;
