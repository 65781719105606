import { Outlet, useLocation } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";
import { useSelector } from "react-redux";
import { RootState } from "app/store";
import { useEffect, useState } from "react";
import { getObjectFromSessionStore } from "app/utils/sessionStore";
import PageHeader from "./InteractiveProposalHeader";
import InteractiveProposalSidebar from "./InteractiveProposalSidebar";

const InteractiveProposalLayout = () => {
  const lookUpResponse = useSelector((state: RootState) => state?.contactProfileLookup);
  const { isLoading } = lookUpResponse;
  const { pathname } = useLocation();
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

  useEffect(() => {
    // Fetch lookup response if needed
    const getLookUpResponse = getObjectFromSessionStore("lookUpResponse");
    if (!getLookUpResponse?.menu) {
      // Dispatch action to fetch data if necessary
    }
  }, [pathname]);

  return (
    <div className="flex h-screen">
      <PageHeader
        title="Interactive Proposal"
        subtitle="XYZ Corporation"
        isCollapsed={isCollapsed}
      />
      <div className="flex flex-1">
        <InteractiveProposalSidebar isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
        <div className="flex-1 overflow-auto h-[calc(100vh-150px)] mt-auto p-4 scrollbar-hide">
          {isLoading ? (
            <div className="flex justify-center items-center h-full">
              <ProgressSpinner />
            </div>
          ) : (
            <Outlet />
          )}
        </div>
      </div>
    </div>
  );
};

export default InteractiveProposalLayout;
